import { Link } from 'react-router-dom';

interface IVeilederDigitalSignering {
    tittel: string;
    beskrivelse: string;
    lenke?: string;
    lenkeTekst?: string;
}

const VeilederDigitalSignering = (props: IVeilederDigitalSignering) => {
    return (
        <div className="col-md-4 col-xl-4 digita-signering-content">
            <div className="section section--white">
                <h2 className="digital-signering-tittel">{props.tittel}</h2>
                <hr />
                <div className="digital-signing-beskrivelse" dangerouslySetInnerHTML={{ __html: props.beskrivelse }}></div>
                {props.lenke ? (
                    <div className="col-md-12 col-xl-12">
                        <Link to={props.lenke} className="btn btn--primary">
                            {props.lenkeTekst}
                        </Link>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default VeilederDigitalSignering;
